import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Button } from '@mui/material';
import { IoDownloadOutline } from "react-icons/io5";
import axios from 'axios';
import { Auth } from "aws-amplify";



const PriceTrend = (props) => {
  // Log props for debugging
  //console.log("pricetrend compo", props.data);

  const formatData = (seriesData) => {
    return seriesData.map(item => [item.date * 1000, item.price]);
  };

  const downloadCSV = async() => {
    const body = {
      commodity: props.cropName,
      market_name: props.marketName,
      crop_variety: props.cropVariety,
      start_year: props.startYear,
      end_year: props.endYear,
      start_month: props.startMonth,
      end_month: props.endMonth,
    }
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const response = await axios.post(
        "/api/explore/downloadPriceData",
        body,
        {
          responseType: "blob", // Ensures the response is treated as a binary file
          headers: {
            "Content-Type": "application/json",
            'Authorization':jwtToken
          },
        }
      );

      // Create a URL for the Excel file
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "pricing_data.xlsx"); // Updated file extension to .xlsx

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Cleaning up after download
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file");
    };
  };

  let series = [];
  let data = [];
  let options = {};
  const getColorForSource = (source) => {
    switch (source) {
      case "Agmarknet":
        return '#18794E';
      case "e-NAM":
        return '#0B68CB';
      case "NCDEX":
        return '#544FC5';
      default:
        return '#ccc'; // Default color for unknown source
    }
  };

  // Loop through each data source in props.data
  Object.keys(props.data).forEach(source => {
    if (props.data[source].length > 0) {
      data = formatData(props.data[source]);
      series.push({
        name: source,
        data: data,
        marker: { enabled: false },
        lineWidth: 1,
        color: getColorForSource(source), // Add function to determine color
        tooltip: { valueSuffix: ' per Quintal' }
      });
    }
  });

  

  options = {
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      title: { text: 'Date' }
    },
    yAxis: {
      title: { text: 'Price' }
    },
    series: series,
    tooltip: {
      shared: true,
      crosshairs: true
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      floating: false,
      y: 10,
    },
    credits: { enabled: false } 
  };

  return (
    <div style={{ position: 'relative', paddingBottom: '50px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
      <div style={{
        position: 'absolute',
        bottom: '10px',
        right: '10px',
      }}>
        <Button 
         variant="outlined"
         startIcon={<IoDownloadOutline />}
          onClick={downloadCSV} 
          style={{
            borderRadius: "8px",
            border: "1px solid #451E11",
            color: "black",
          }}
        >
          Download Graph Data
        </Button>
      </div>
    </div>
  );
};

export default PriceTrend;